<template>
  <div class="agent">
    <div v-if="apiError !== 0">
      Hiba történt az API-hívás során: {{ apiError }}
    </div>
  </div>
</template>

<script>
import { typingEvent, sendMessage } from "@/socket";

export default {
  data() {
    return {
      message: "",
      isTyping: false,
      userToken: null,
      clientToken: "76rYY1aKh9bOXk4vPBYZqXKyKEC55uX8",
      agentToken: null,
      receivedMessages: [],
      apiError: 0,
    };
  },
  mounted() {
    this.userToken = localStorage.getItem("userToken");
    this.agentToken = localStorage.getItem("agentToken");

    this.$socket.on("message", (message) => {
      console.log("Message received in AgentComponent:", message);
    });
  },
  methods: {
    handleTyping() {
      if (this.message.trim()) {
        console.log("Agent typing");
        typingEvent(
          this.message,
          this.agentToken,
          this.userToken,
          this.clientToken
        );
      }
    },

    sendMessage() {
      if (this.message.trim()) {
        console.log("Agent sent message");
        sendMessage(
          this.message,
          this.agentToken,
          this.userToken,
          this.clientToken
        );
        this.message = "";
      }
    },
  },
};
</script>

<style scoped>
.agent {
  display: flex;
  flex-direction: column;
}
textarea {
  margin-bottom: 10px;
}
</style>
