import io from "socket.io-client";

// Socket létrehozása
const socket = io("https://socket.octagent.com:3000", {
  withCredentials: true,
  extraHeaders: {
    "Access-Control-Allow-Origin": "*",
  },
});

socket.on("connect", () => {
  console.log("Socket kapcsolat létrejött");

  // Tokenek eltárolása és lekérése a connect eseménynél
  let agentToken = localStorage.getItem("agentToken");
  let userToken = localStorage.getItem("userToken");

  if (agentToken && userToken) {
    socket.emit("connected", {
      agentToken,
      userToken,
    });
    console.log("Tokenek elküldve a szervernek:", { agentToken, userToken });
  } else {
    console.error(
      "Hiányzó tokenek. Kérem ellenőrizze, hogy mindkét token elérhető."
    );
  }
});

// Események exportálása
export function typingEvent(message, agentToken, userToken, clientToken) {
  socket.emit("typing", {
    message,
    agentToken,
    userToken,
    clientToken,
  });
}

export function sendMessage(message, agentToken, userToken, clientToken) {
  socket.emit("message", {
    message,
    agentToken,
    userToken,
    clientToken,
  });
}

export default socket;
